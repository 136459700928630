


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    InsLogo: () => import('@/components/base/InsLogo.vue'),
    InsSearch: () => import('@/components/business/header/InsSearch.vue'),
    Menu: () => import('@/components/business/header/InsElMenu.vue'),
    // Menu: () => import('@/components/business/header/InsElMenu2.vue'),
    InsLangSwitch: () =>
      import('@/components/business/header/InsLangSwitch.vue')
  }
})
export default class InsMenuLayout extends Vue {
  showMemNav: boolean = false;
  searchKey: string = '';

  handleOpen (key, keyPath) {
    console.log(key, keyPath);
  }
  handleClose (key, keyPath) {
    console.log(key, keyPath);
  }

  closeSlideMenu () {
    this.$store.dispatch('isShowMenu', false);
  }

  //   private changLange (lang) {
  //     this.$Api.member.setUILanguage(lang === 'zh' ? 'C' : 'E').then((result) => {
  //       this.$i18n.locale = lang;
  //       localStorage.setItem('locale', lang);

  //       this.closeSlideMenu();
  //     }).catch((error) => {
  //       console.log(error);
  //     });
  //   }

  //   changeLang (lang) {
  //     this.$Api.member.setUILanguage(lang).then((result) => {
  //       this.$i18n.locale = lang;
  //       localStorage.setItem('locale', lang);

  //       window.location.reload();
  //     }).catch((error) => {
  //       console.log(error);
  //     });
  //   }

  get user () {
    return this.$store.state.user;
  }

  get isLogin () {
    return this.$store.state.isLogin;
  }
}
